import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Axios, { AxiosInstance } from 'axios';
import { IFilterQuery } from 'interfaces/IFilterQuery';
import { IResponse } from 'interfaces/IResponse';
import { API_BASE_URL } from 'utils';

const baseUrl = `${API_BASE_URL}/ws/v2/nft/`;

export const collectionApi = createApi({
  reducerPath: 'collection',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('@nft.arc.market:jwt');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getCollections: builder.query<IResponse, IFilterQuery | void>({
      query: (filter: IFilterQuery | void) => {
        let filters = '[{"fieldName":"","query":""}]';
        if (filter && filter.fieldName && filter.query) {
          filters = `[{"fieldName":"${filter.fieldName}","query":"${filter.query}"}]`;
        } else if (filter && filter.query) {
          filters = `[{"fieldName":"name","query":"${filter.query}"}]`;
        } else if (!filter) {
          return {
            url: 'collection',
            params: { limit: 3 },
          };
        }
        return {
          url: 'collection',
          params: { filters, limit: 3 },
        };
      },
    }),
    getCollectionsByTag: builder.query<IResponse, string | void>({
      query: (_tag: string) => `collection/tag/${_tag}`,
    }),
    getCollection: builder.query<IResponse, string | void>({
      query: (_id: string) => `collection/${_id}`,
    }),
    getCollectionByURL: builder.query<IResponse, string | void>({
      query: (url: string) => `collection/url/${url}`,
    }),
    getTopCollections: builder.query<IResponse, void>({
      query: () => `collection/top`,
    }),
    getItems: builder.query<IResponse, string | void>({
      query: (_id: string) => `collection/${_id}/items`,
    }),
    getOwners: builder.query<IResponse, string | void>({
      query: (_id: string) => `collection/${_id}/owners`,
    }),
    getHistory: builder.query<IResponse, string | void>({
      query: (_id: string) => `collection/${_id}/history`,
    }),
    getCollectionOffer: builder.query<IResponse, string | void>({
      query: (_id: string) => `collection/${_id}/offer`,
    }),
    getActivity: builder.mutation<IResponse, { _id: string | void; page: number; filter: IFilterQuery }>({
      query: ({ _id, page, filter }) => ({
        url: `collection/${_id}/activity?page=${page}&limit=10&filters=${JSON.stringify([filter])}`,
        method: 'get',
      }),
    }),
    create: builder.mutation<IResponse, FormData>({
      query: (body) => ({
        url: 'collection/create',
        method: 'post',
        body,
      }),
    }),
    update: builder.mutation<IResponse, any>({
      query: ({ _id, body }) => ({
        url: `collection/${_id}`,
        method: 'put',
        body,
      }),
    }),
    placeBid: builder.mutation({
      query: (body) => ({
        url: 'collection/placeBid',
        method: 'post',
        body,
      }),
    }),
  }),
});

export const {
  useGetCollectionQuery,
  useGetCollectionsByTagQuery,
  useGetCollectionByURLQuery,
  useGetCollectionsQuery,
  useGetTopCollectionsQuery,
  useGetItemsQuery,
  useGetOwnersQuery,
  useGetHistoryQuery,
  useGetCollectionOfferQuery,
  useGetActivityMutation,
  useCreateMutation,
  useUpdateMutation,
  usePlaceBidMutation,
} = collectionApi;

type ColParams = {
  limit?: number;
  orderBy?: string;
  direction?: string;
  page?: number;
};

export class CollectionLoader {
  api: AxiosInstance;
  data: any[];
  count: number;
  page: number;
  limit: number;
  maxPage: number;
  orderBy?: string;
  direction?: string;

  constructor() {
    this.api = Axios.create({ baseURL: baseUrl });
    this.data = [];
    this.limit = 5;
    this.count = 0;
    this.page = 1;
    this.maxPage = 0;
  }
  calcMaxPage = () => {
    // return Math.ceil(count / limit);
    this.maxPage = Math.ceil(this.count / this.limit);
  };
  toParams = (): string => {
    const s: string[] = [];
    this.limit && s.push(`limit=${this.limit}`);
    if (this.orderBy) {
      s.push(`orderBy=${this.orderBy}`);
      this.direction && s.push(`direction=${this.direction}`);
    }
    this.page && s.push(`page=${this.page}`);
    return s.join('&');
  };
  refresh: (param: ColParams) => Promise<{
    data: any[];
    count: number;
  }> = async ({ limit = 2, orderBy, direction } = {}) => {
    orderBy && (this.orderBy = orderBy);
    direction && (this.direction = direction);
    this.limit = limit;
    this.page = 1;
    const { data: fetchedData } = await this.api.get(`collection?${this.toParams()}`);
    this.data = fetchedData.data;
    this.count = fetchedData.count;
    this.calcMaxPage();
    return { data: this.data, count: this.count };
  };
  loadMore = async () => {
    if (this.page < this.maxPage) {
      this.page++;
      const { data: fetchedData } = await this.api.get(`collection?${this.toParams()}`);
      this.data = this.data.concat(fetchedData.data);
      // return this.data;
      this.calcMaxPage();
    }
    return this.data;
  };
}
