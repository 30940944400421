export enum SupportedChainId {
  MAINNET = 1,
  RINKEBY = 4,
  HEX_MAINNET = '0x1',
  HEX_RINKEBY = '0x4',
}

type AddressMap = { [chainId: number]: string };

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [SupportedChainId.RINKEBY]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
};

export const ARC_TOKEN_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2',
  [SupportedChainId.RINKEBY]: '0x9Baa392bF91042F605b71582D7B494e812584D2e',
};

export const EXCHANGE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x0b1aD9eA9663039feA34C6484740fe0ADa479A9A',
  [SupportedChainId.RINKEBY]: '0xa0374D45f96284837f0d9F38474ccC35dBfDf2f3',
};

export const CURRENCY_MANAGER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xf24a78c39fF7eef030b58Cd28616d5054A6060ef',
  [SupportedChainId.RINKEBY]: '0x727271DB126dBa9BD576744a8563ec42dB8Fc231',
};

export const EXECUTION_MANAGER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x18D06331BC3d77eAaEe89fA906F9663F8bfB18e9',
  [SupportedChainId.RINKEBY]: '0xBE7B93518430DBfD69483218b6aCD38C70DB2a18',
};

export const ROYALTY_FEE_MANAGER_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x1C7cB9715e547488c4cb1ba4101076871a935CfD',
  [SupportedChainId.RINKEBY]: '0x4e902d71f88D2984b02C9DFCd386abf335029bfe',
};

export const TRANSFER_SELECTOR_NFT_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x3d8c75d6564e42945778234ac6403c2a993d259C',
  [SupportedChainId.RINKEBY]: '0x18DE78AA609dCEF8c4CF9495c8BdD755fc8c8F0E',
};

export const ROYALTY_FEE_REGISTRY_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x4DEb0c25ec0F58543ffb81f9BcC89D44b1f468C0',
  [SupportedChainId.RINKEBY]: '0x5208f3B43324E78299e8Bc40bbc482855E51ac54',
};

export const STRATEGY_ANY_ITEM_FROM_COLLECTION_FOR_FIXED_PRICE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x598bd0810a532bc3Ec3fC9B53Ae4e011Fc73cd07',
  [SupportedChainId.RINKEBY]: '0x31b06f5E67187fe9f95Db4d99E74a088aACcEe81',
};

export const STRATEGY_PRIVATE_SALE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xb6e49E50A95015F4532FF4575376dDFB41431076',
  [SupportedChainId.RINKEBY]: '0xf65EADb6F7ABc7F54253cF41795e6cbcB6532413',
};

export const STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xDf3eebeb3d3027203E03C70aB4C2310d0056525B',
  [SupportedChainId.RINKEBY]: '0x0d28Ee167123B472f6575a1f043B1f40893eAE44',
};

export const TRANSFER_MANAGER_ERC1155_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xa787364d1BF685A818297E96A48fFC96C9f78904',
  [SupportedChainId.RINKEBY]: '0x3BFA946191548C89C6804cF93570230d28044C05',
};

export const TRANSFER_MANAGER_ERC721_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x443997Ff5979307d0AEF0899996a78BBD0782a1a',
  [SupportedChainId.RINKEBY]: '0x46DEBd032fE6a793ffDC9bdeEEBa93DD5F8a9268',
};

export const ARC721_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xdb4C57E96EFf8ae5123aE35bc9bd686B34685fcA',
  [SupportedChainId.RINKEBY]: '0x2B474979BB8786F8526fAB60691896D6aF5b5383',
};

export const ARC1155_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x613e3AC3a750BAF7219aB25E6fd55302836Bb756',
  [SupportedChainId.RINKEBY]: '0x435a0E86B8f1e3933914a0305e1b356427B2e036',
};

export const WETH_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  [SupportedChainId.RINKEBY]: '0xc778417e063141139fce010982780140aa0cd5ab',
};
