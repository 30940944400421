import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IFilterQuery } from 'interfaces/IFilterQuery';
import { IResponse } from 'interfaces/IResponse';
import { API_BASE_URL } from 'utils';

const baseUrl = `${API_BASE_URL}/ws/v2/nft/owners`;

type IUploadPhotoRequest = {
  address: string;
  body: FormData;
};

export const ownerApi = createApi({
  reducerPath: 'owner',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('@nft.arc.market:jwt');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getOwners: builder.query<IResponse, any>({
      query: (body) => {
        const { orderBy, direction, filters } = body;
        return ``;
      },
    }),
    getOwner: builder.query<IResponse, string | void>({
      query: (address: string) => `/${address}`,
    }),
    getNfts: builder.query<IResponse, string | void>({
      query: (address: string) => `/${address}/nfts`,
    }),
    getCollections: builder.query<IResponse, string | void>({
      query: (address: string) => `/${address}/collection`,
    }),
    getHistory: builder.mutation<IResponse, { address: string | void; page: number; filter: IFilterQuery }>({
      query: ({ address, page, filter }) => ({
        url: `/${address}/history?page=${page}&limit=10&filters=${JSON.stringify([filter])}`,
        method: 'get',
      }),
    }),
    getActiveList: builder.mutation<IResponse, { address: string | void; page: number; filter: IFilterQuery }>({
      query: ({ address, page, filter }) => ({
        url: `/${address}/activelist?page=${page}&limit=10&filters=${JSON.stringify([filter])}`,
        method: 'get',
      }),
    }),
    getRelist: builder.mutation<IResponse, { address: string | void }>({
      query: ({ address }) => ({
        url: `/${address}/relist`,
        method: 'get',
      }),
    }),
    getExpiredList: builder.mutation<IResponse, { address: string | void; page: number; filter: IFilterQuery }>({
      query: ({ address, page, filter }) => ({
        url: `/${address}/expiredlist?page=${page}&limit=10&filters=${JSON.stringify([filter])}`,
        method: 'get',
      }),
    }),
    getNonce: builder.mutation<IResponse, { address: string | void }>({
      query: ({ address }) => ({
        url: `/${address}/getnonce`,
        method: 'get',
      }),
    }),
    getOffers: builder.mutation<IResponse, { address: string | void; page: number }>({
      query: ({ address, page }) => ({
        url: `/${address}/offers?page=${page}&limit=10`,
        method: 'get',
      }),
    }),
    update: builder.mutation<IResponse, any>({
      query: ({ address, ...patch }) => ({
        url: `/${address}`,
        method: 'put',
        body: patch,
      }),
    }),
    uploadPhoto: builder.mutation<IResponse, IUploadPhotoRequest>({
      query: ({ address, body }) => ({
        url: `/${address}/upload-profile`,
        method: 'post',
        body,
      }),
    }),
  }),
});

export const {
  useGetOwnersQuery,
  useGetOwnerQuery,
  useGetNftsQuery,
  useGetCollectionsQuery,
  useGetHistoryMutation,
  useGetOffersMutation,
  useUpdateMutation,
  useUploadPhotoMutation,
  useGetNonceMutation,
  useGetActiveListMutation,
  useGetExpiredListMutation,
  useGetRelistMutation,
} = ownerApi;
